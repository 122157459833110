/**
 * Не использовать async/await, потому что загружаемые Parcel полифиллы не дружат с IE11
 */

import './ymaps';

var aa_user_balance  = 0;
window.aa_ext_dwnld_url = null;
var aa_ext_dwnld_timer = null;

window.openVideoPopup = function openVideoPopup(activeClass, type) {
    event.preventDefault();
    var videoPopup = document.getElementById('video-popup');
    switch (type) {
        case 'youtube': {
            var videoPlayer =
                '<iframe width="560" height="315" src="//www.youtube.com/embed/zrxn0PVKeE0?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen=""></iframe>';
            break;
        }
        case 'video': {
            var target = event.target;
            if (target.closest('[data-type="link"]')) {
                var videoLink = target.closest('[data-href]').dataset.href;
                var videoPlayer =
                    '<video controls width="560" height="315">\n' +
                    '  <source src="' +
                    videoLink +
                    '" type="video/mp4">\n' +
                    '</video>';
            }
            break;
        }
        default: {
            return false;
        }
    }
    var template =
        '<div class="video-popup__center">\n <div class="video-popup__block">\n <button data-type="close-modal" class="video-popup__close" type="button"></button>\n <div class="video-popup__video">' +
        videoPlayer +
        '</div>\n </div>\n </div>';
    videoPopup.innerHTML = template;
    videoPopup.classList.add(activeClass);
    var closeModal = videoPopup.querySelector('[data-type="close-modal"]');
    closeModal.addEventListener('click', {
        handleEvent: closeVideoPopup,
        popup: videoPopup,
        activeClass: activeClass,
        closeModal: closeModal,
    });
}

window.closeVideoPopup = function closeVideoPopup(event) {
    this.popup.classList.remove(this.activeClass);
    this.popup.innerHTML = '';
}

window.validatePhone = function validatePhone(input) {
    var value = input.value;
    var rep = /[^0-9+()-]/;
    if (rep.test(value)) {
        value = value.replace(rep, '');
        input.value = value;
    }
}

window.moveToAnchor = function moveToAnchor(anc) {
    var obj = jQuery('a[name = ' + anc + ']').eq(0);
    var topOffset = 150;
    if (window.innerWidth < 900) {
        topOffset = 10;
    }
    var pos = obj.offset().top - topOffset; //110
    jQuery('body,html').animate({ scrollTop: pos }, 800);
    return false;
}

window.moveToAnchorDiv = function moveToAnchorDiv(anc, div) {
    var obj = jQuery(anc);
    var ojDiv = jQuery(div);
    var pos = obj.offset().top - ojDiv.offset().top + ojDiv.scrollTop();
    ojDiv.animate({ scrollTop: pos }, 200);
    return false;
}

window.aa_filterDoc = function aa_filterDoc(obj) {
    var oldType,
        newType = jQuery('.a-sort-1 a.aa-filter-bg').eq(0);
    var oldClass,
        newClass = jQuery('.a-sort-2 a.aa-filter-bk').eq(0);
    var currEl = jQuery(obj);
    if (currEl.data('aa-type') != undefined) newType = currEl;
    else if (currEl.data('aa-class') != undefined) newClass = currEl;
    if (oldType == newType && oldClass == newClass) return false;

    jQuery('.a-sort-1 a').removeClass('aa-filter-bg');
    jQuery('.a-sort-2 a').removeClass('aa-filter-bk');

    newType.addClass('aa-filter-bg');
    newClass.addClass('aa-filter-bk');
    var ie8 = 'class';
    var ieObj = { data: { type: newType.data('aa-type') } };
    ieObj['data'][ie8] = newClass.data('aa-class');
    jQuery.fn.yiiListView.update('ajaxListView', ieObj);
    return false;
}

window.olp_is_new = false;

$(function () {
    var url = decodeURI(window.location);
    var pos = url.indexOf('#');
    if (pos >= 0) {
        var id = url.substring(pos + 1);
        id = id.split('@', 2);
        if (id.length == 2) {
            //            console.log(id);
            aa_olp_tab_activate(id[0], id[1]);
        }
    }

    $(document).click(function (event) {
        if ($(event.target).closest('#aa-school-global-list').length) {
            jQuery('.d-order-full-name').html('');
            return;
        }

        if (
            $(event.target).closest('.d-order-full-name').length ||
            $(event.target).closest('.dr-olimp-order-wrap').length
        ) {
            jQuery('#aa-school-global-list').remove();
            return;
        }

        jQuery('#aa-school-global-list').remove();
        jQuery('.d-order-full-name').html('');

        event.stopPropagation();
    });
});

window.dr_menuCabinetToggle = function dr_menuCabinetToggle() {
    var $menuCabinet = $('.dr-cabinet-toggle'),
        $menuCabinetForm = $('.dr-cabinet-form'),
        $menuRegistration = $('.a-registration');

    $($menuRegistration).hover(
        function (e) {
            if ($menuCabinetForm.css('position') === 'absolute') {
                e.preventDefault();
                $menuRegistration.addClass('dr-open');
                $menuCabinetForm.slideDown(0);
            }
        },
        function (e) {
            if ($menuCabinetForm.css('position') === 'absolute') {
                e.preventDefault();
                $menuRegistration.removeClass('dr-open');
                $menuCabinetForm.slideUp(0);
            }
        },
    );
}

window.dr_menuMobileToggle = function dr_menuMobileToggle(attr) {
    $('.' + attr).slideToggle(300);
    return false;
}

window.aa_init_comment = function aa_init_comment(id_obj, type_obj) {
    if (jQuery('#aa-comment').length) {
        //jQuery('#aa-comment').aaComments('destroy');
        //jQuery('#aa-comment').aaComments({id_obj: id_obj, type_obj: type_obj});
    }

    if (jQuery('#Comment_comment').length) {
        //        jQuery('#Comment_comment').redactor('destroy');
        //        jQuery('#Comment_comment').redactor('core.destroy');
        jQuery('#Comment_comment').redactor({
            buttons: ['bold', 'italic', 'deleted', 'underline'],
        });
    }
}

// включение функционала для комментариев
window.ag_comment_init = function ag_comment_init(settings) {
    //jQuery('#aa-comment').aaComments('destroy');
    jQuery('#aa-comment').aaComments(settings);
}

// подгрузка новых комментариев при скролле
window.ag_comment_more_scroll = function ag_comment_more_scroll(obj) {
    if ($(obj).scrollTop() != 0) {
        var r = $(obj).height();
        var b = document.getElementById('aa-more-scroll');
        if (b !== null) {
            var rect = document.getElementById('aa-more-scroll').getBoundingClientRect();
            //console.log(\"scrollTop\",$(this).scrollTop(),\" more pos:\",rect.top,\" more windowH:\",r);
            if (rect !== null && r > rect.top && rect.top > 0 && !ag_comment_loads) {
                ag_comment_loads = true;
                jQuery('#aa-comment').aaComments('more');
            }
        }
    }
}

window.ag_avatar_click = function ag_avatar_click(ev, obj, id, a, b) {
    if (ev.target.id == obj.id) {
        iu.dlg(ev, id, a, b);
    }
    //console.log('event.id:',ev.target.id,' this.id:',obj.id);
}

window.aa_init_editor = function aa_init_editor(id) {
    if (!jQuery(id).length) return;
    //    jQuery(id).redactor('core.destroy');
    jQuery(id).redactor({
        buttons: [
            'formatting',
            'bold',
            'italic',
            'deleted',
            'underline',
            'unorderedlist',
            'orderedlist',
            'alignment',
        ],
        minHeight: 300,
        maxHeight: 800,
        linkSize: 256,
        lang: 'ru',
    });
}

window.aa_init_upload = function aa_init_upload(id, url, prefix) {
    var init = jQuery(id).data('ajax-form');
    if (init === undefined) {
        var callback = null;
        if (arguments[3] !== undefined) callback = arguments[3];
        var withCredentials = true;
        if (arguments[4] !== undefined) withCredentials = arguments[4];
        var uploadProgressCallback = null;
        if (arguments[5] !== undefined) uploadProgressCallback = arguments[5];
        jQuery(id).ajaxForm({
            url: url,
            xhrFields: {
                withCredentials: withCredentials,
            },
            dataType: 'json',
            error: function () {
                jQuery('#aa-' + prefix + '-act input[type=file]').val('');
                iu.popup.error(
                    'Размер прикрепленного Вами файла слишком большой, попробуйте сократить его или разбить на части.',
                );
                jQuery('#aa-' + prefix + '-act').fadeIn(300);
                jQuery('#aa-' + prefix + '-prg').slideUp(300);
                if (jQuery('#aa-' + prefix + '-wht').length)
                    jQuery('#aa-' + prefix + '-wht').fadeOut(300);
            },
            beforeSubmit: function (arr, $form, options) {
                var percentVal = '0';
                jQuery('#aa-' + prefix + '-prg .bar').width(percentVal);
                jQuery('#aa-' + prefix + '-prg .percent').html(percentVal);
                jQuery('#aa-' + prefix + '-act').fadeOut(300);
                jQuery('#aa-' + prefix + '-prg').slideDown(300);
                if (jQuery('#aa-' + prefix + '-wht').length)
                    jQuery('#aa-' + prefix + '-wht').fadeOut(300);
            },
            uploadProgress: function (event, position, total, percentComplete) {
                if (uploadProgressCallback) {
                    uploadProgressCallback(percentComplete);
                }
                if (jQuery('#aa-' + prefix + '-wht').length && percentComplete > 90) {
                    jQuery('#aa-' + prefix + '-prg').slideUp(300);
                    jQuery('#aa-' + prefix + '-wht').fadeIn(300);
                } else {
                    var percentVal = percentComplete + '%';
                    jQuery('#aa-' + prefix + '-prg .bar').width(percentVal);
                    jQuery('#aa-' + prefix + '-prg .percent').html(percentVal);
                }
            },
            success: function (data) {
                jQuery('#aa-' + prefix + '-act input[type=file]').val('');
                if (data.error != undefined) {
                    iu.popup.error(data.error);
                } else if (typeof callback === 'function') callback(data);

                jQuery('#aa-' + prefix + '-act').fadeIn(300);
                jQuery('#aa-' + prefix + '-prg').slideUp(300);
                if (jQuery('#aa-' + prefix + '-wht').length)
                    jQuery('#aa-' + prefix + '-wht').fadeOut(300);
            },
        });
        //        jQuery(id).data('ajax-form', true);
    }
    jQuery(id).submit();
    jQuery(id).ajaxFormUnbind();
}

window.aa_init_upload_multi = function aa_init_upload_multi(id, url, prefix, data, callback) {
    jQuery.ajax({
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        type: 'POST',
        url: url,
        data: data,
        xhrFields: {
            withCredentials: true,
        },
        error: function () {
            callback(null);
        },
        success: function (data) {
            if (data.error != undefined) {
                iu.popup.error(data.error);
            } else if (typeof callback === 'function') {
                callback(data);
            }
        },
    });
}

window.aa_up_balance = function aa_up_balance(e) {
    iu.ajax(e, '/backOffice/ajaxUpBalance', '#aa-pre-payment-form', function (data) {
        if (data.data !== undefined) {
            jQuery('body').append(data.data);
            jQuery('#aa-payment-form').submit();
        }
    });
    return false;
}

window.aa_prod_pay = function aa_prod_pay(e) {
    iu.ajax(e, '/product/ajaxOrderStepEPay', '#aa-product-step2-form', function (data) {
        if (data.data !== undefined) {
            jQuery('body').append(data.data);
            jQuery('#aa-payment-form').submit();
        }
    });
    return false;
}

window.aa_prod_pay_fast = function aa_prod_pay_fast(e, id, ref) {
    iu.ajax(e, '/product/ajaxOrderStepEPayFast', { id: id, ref: ref }, function (data) {
        if (data.data !== undefined) {
            jQuery('body').append(data.data);
            jQuery('#aa-payment-form').submit();
        }
    });
    return false;
}

window.aa_pay_rk = function aa_pay_rk(e, url) {
    var t_data = {};
    if (arguments[2] !== undefined) t_data = arguments[2];
    iu.ajax(e, url, t_data, function (data) {
        if (data.data !== undefined) {
            jQuery('body').append(data.data);
            jQuery('#aa-payment-form').submit();
        }
    });
    return false;
}

window.aa_del_avatar = function aa_del_avatar() {
    jQuery('#aa-editAvatar-act').fadeOut(300);
    jQuery('#aa-editAvatar-wht').fadeIn(300);
    jQuery('#aa-editAvatar-prg').slideUp(300);
    iu.ajax(null, '/ajax/delAvatar', {}, function (data) {
        if (data.data !== undefined) {
            jQuery('#aa-avatar').attr('src', data.data.src);
            if (!data.data.avatar) jQuery('#d-x').css('display', 'none');
        }
        jQuery('#aa-editAvatar-act').fadeIn(300);
        jQuery('#aa-editAvatar-wht').fadeOut(300);
        jQuery('#aa-editAvatar-prg').slideUp(300);
    });
    return false;
}

window.aa_set_avatar = function aa_set_avatar(id) {
    iu.ajax(null, '/ajax/setAvatar', { id: id }, function (data) {
        if (data.data !== undefined) {
            jQuery('#aa-avatar').attr('src', data.data.src);
            jQuery('#d-x').css('display', 'inline');
        }
    });
    return false;
}

window.aa_del_image = function aa_del_image(e, id) {
    var confirm = true;
    if (arguments[2] !== undefined) confirm = arguments[2];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить фотографию',
            'Вы уверены что хотите удалить эту фотографию?',
            function () {
                aa_del_image(e, id, false);
            },
        );
        return;
    }

    jQuery('#aa-add-image-form [data-id=' + id + ']').hide();
    jQuery('.aa-photo[data-id=' + id + ']').hide();
    iu.dlg.hide();

    iu.ajax(e, '/ajax/delImage', { id: id }, function (data) {
        if (data.error !== undefined) {
            jQuery('#aa-add-image-form [data-id=' + id + ']').show();
            jQuery('.aa-photo[data-id=' + id + ']').show();
        } else {
            jQuery('#aa-add-image-form [data-id=' + id + ']').remove();
            jQuery('.aa-photo[data-id=' + id + ']').remove();
        }
    });
    if (jQuery('#aa-add-image-form div[data-id]').length >= 5)
        jQuery('#ag-add-image-form-submit').show();
    else jQuery('#ag-add-image-form-submit').hide();
    return false;
}

window.aa_rotate_image = function aa_rotate_image(e, id, d) {
    jQuery('img.aa-photo-src-b[data-id=' + id + ']').addClass('aa-load1');
    jQuery('img.aa-photo-src-m[data-id=' + id + ']').addClass('aa-load1');
    jQuery('img.aa-photo-src-s[data-id=' + id + ']').addClass('aa-load1');

    iu.ajax(e, '/ajax/rotateImage', { id: id, d: d }, function (data) {
        if (data.img !== undefined) {
            var image_b = new Image();
            image_b.onload = function (e) {
                jQuery('img.aa-photo-src-b[data-id=' + id + ']').attr('src', data.img.b);
                jQuery('img.aa-photo-src-b[data-id=' + id + ']').removeClass('aa-load1');
            };
            image_b.src = data.img.b;

            var image_m = new Image();
            image_m.onload = function (e) {
                jQuery('img.aa-photo-src-m[data-id=' + id + ']').attr('src', data.img.m);
                jQuery('img.aa-photo-src-m[data-id=' + id + ']').removeClass('aa-load1');
            };
            image_m.src = data.img.m;

            var image_s = new Image();
            image_s.onload = function (e) {
                jQuery('img.aa-photo-src-s[data-id=' + id + ']').attr('src', data.img.s);
                jQuery('img.aa-photo-src-s[data-id=' + id + ']').removeClass('aa-load1');
            };
            image_s.src = data.img.s;
        }
    });
}

window.aa_del_album = function aa_del_album(e, id) {
    var confirm = true;
    var redirect = 0;
    if (arguments[2] !== undefined) redirect = arguments[2];
    if (arguments[3] !== undefined) confirm = arguments[3];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить альбом',
            'Вы уверены что хотите удалить этот альбом?',
            function () {
                aa_del_album(e, id, redirect, false);
            },
        );
        return;
    }

    jQuery('.aa-album[data-id=' + id + ']').hide();
    iu.ajax(e, '/ajax/delAlbum', { id: id, redirect: redirect }, function (data) {
        if (data.error !== undefined) {
            jQuery('.aa-album[data-id=' + id + ']').show();
        } else {
            jQuery('.aa-album[data-id=' + id + ']').remove();
        }
    });
    return false;
}

window.aa_del_blog = function aa_del_blog(e, id) {
    var confirm = true;
    var redirect = 0;
    if (arguments[2] !== undefined) redirect = arguments[2];
    if (arguments[3] !== undefined) confirm = arguments[3];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить запись в блоге',
            'Вы уверены что хотите удалить эту запись в блоге?',
            function () {
                aa_del_blog(e, id, redirect, false);
            },
        );
        return;
    }

    jQuery('.aa-blog[data-id=' + id + ']').hide();
    iu.ajax(e, '/ajax/delBlog', { id: id, redirect: redirect }, function (data) {
        if (data.error !== undefined) {
            jQuery('.aa-blog[data-id=' + id + ']').show();
        } else {
            jQuery('.aa-blog[data-id=' + id + ']').remove();
        }
    });
    if (!redirect) {
        location.reload();
    }
    return false;
}

window.aa_del_progress = function aa_del_progress(e, id) {
    var confirm = true;
    if (arguments[2] !== undefined) confirm = arguments[2];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить запись в достижениях',
            'Вы уверены что хотите удалить эту запись о достижении?',
            function () {
                aa_del_progress(e, id, false);
            },
        );
        return;
    }

    iu.ajax(e, '/ajax/delProgress', { id: id });
    return false;
}

window.aa_del_page = function aa_del_page(e, id) {
    var confirm = true;
    if (arguments[2] !== undefined) confirm = arguments[2];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить статическую страницу',
            'Вы уверены что хотите удалить эту статическую страницу?',
            function () {
                aa_del_page(e, id, false);
            },
        );
        return;
    }

    iu.ajax(e, '/ajax/delPage', { id: id });
    return false;
}

window.aa_del_user = function aa_del_user(e) {
    var confirm = true;
    if (arguments[1] !== undefined) confirm = arguments[1];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Отправить запрос на удаление сайта',
            '<b>Внимание!</b><br /><br />Это действие необратимо!<br />Вы уверены, что хотите удалить свой сайт?',
            function () {
                aa_del_user(e, false);
            },
        );
        return;
    }

    $('#delete-user-form').submit();
    return false;
}

window.aa_add_friend = function aa_add_friend(id) {
    jQuery('.aa-friend[data-id=' + id + ']').html(
        '<a class="st-button st-gray dg-display-b" href="javascript:;" onclick="aa_del_friend(' +
            id +
            ');">Удалить из друзей</a>',
    );
    iu.ajax(null, '/ajax/addFriend', { id: id }, function (data) {
        if (data.error !== undefined) {
            jQuery('.aa-friend[data-id=' + id + ']').html(
                '<a href="javascript:;" class="st-button st-green dg-display-b" onclick="aa_add_friend(' +
                    id +
                    ');">Добавить в друзья</a>',
            );
        }
    });
    return false;
}

window.aa_del_friend = function aa_del_friend(id) {
    jQuery('.aa-friend[data-id=' + id + ']').html(
        '<a href="javascript:;" class="st-button st-green dg-display-b" onclick="aa_add_friend(' +
            id +
            ');">Добавить в друзья</a>',
    );
    iu.ajax(null, '/ajax/delFriend', { id: id }, function (data) {
        if (data.error !== undefined) {
            jQuery('.aa-friend[data-id=' + id + ']').html(
                '<a class="st-button st-gray dg-display-b" href="javascript:;" onclick="aa_del_friend(' +
                    id +
                    ');">Удалить из друзей</a>',
            );
        }
    });
    return false;
}

window.aa_pe_add_part = function aa_pe_add_part() {
    iu.ajax(null, '/ajax/peAddPart', '#aa-pe-part-form', function (data) {
        if (data.data !== undefined) {
            jQuery('#aa-up-parts').append(data.data.html);
            jQuery('#aa-up-parts').sortable('destroy');
            jQuery('#aa-up-parts').sortable({
                delay: '300',
                update: aa_pe_sort,
                axis: 'y',
                handle: '.a-peremestyt',
                scroll: true,
            });
        }
    });
    return false;
}

window.ag_confirm_pay = function ag_confirm_pay(e, sum) {
    var confirm = true;
    if (arguments[2] !== undefined) id = arguments[2];
    if (arguments[3] !== undefined) ref = arguments[3];
    if (arguments[4] !== undefined) confirm = arguments[4];
    if (false /*confirm*/) {
        iu.dlg.confirm(
            e,
            'Оплатить с баланса',
            '<b>Внимание!</b><br /><br />С Вашего баланса будет списано ' +
                sum +
                ' руб. за выбранные видеоуроки. Вы согласны?',
            function () {
                ag_confirm_pay(e, sum, id, ref, false);
                //aa_prod_pay_fast(e, id, ref);
            },
        );
        //console.log("confirm:",confirm);
        return;
    }
    aa_prod_pay_fast(e, id, ref);
    //console.log("confirm:",confirm);
    return false;
}

window.aa_pe_del_part_item = function aa_pe_del_part_item(id) {
    var body = jQuery('#UserPagePart_body').val();
    var ids = body.split(',');
    body = '';
    for (var i = 0; i < ids.length; i++) {
        if (ids[i] == id) continue;
        if (body) body += ',';
        body += ids[i];
    }
    jQuery('#UserPagePart_body').val(body);
    jQuery('#aa-page-part-items [data-id=' + id + ']').remove();
}

window.aa_progress_file_del = function aa_progress_file_del() {
    jQuery('#UserProgress_file').val('');
    jQuery('#aa-progress-file').hide();
    jQuery('#aa-progress-file-items').html('');
    jQuery('#aa-progress-addFile').show();
}

window.aa_pe_del = function aa_pe_del(e, id) {
    var confirm = true;
    if (arguments[2] !== undefined) confirm = arguments[2];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить текстовый блок',
            'Вы уверены что хотите удалить этот текстовый блок?',
            function () {
                aa_pe_del(e, id, false);
            },
        );
        return;
    }

    var page = jQuery('#aa-up-parts').data('page');
    jQuery('#aa-up-parts [data-part=aa-part-' + id + ']').hide();

    iu.ajax(e, '/ajax/peDel', { page: page, part: id }, function (data) {
        if (data.error !== undefined) {
            jQuery('#aa-up-parts [data-part=aa-part-' + id + ']').show();
        } else {
            jQuery('#aa-up-parts [data-part=aa-part-' + id + ']').remove();
            if (jQuery('#aa-up-parts>div').length >= 3) jQuery('.aa-pe-save-btn').show();
            else jQuery('.aa-pe-save-btn').hide();
        }
        jQuery('#aa-up-parts').sortable('destroy');
        jQuery('#aa-up-parts').sortable({
            delay: '300',
            update: aa_pe_sort,
            axis: 'y',
            handle: '.a-peremestyt',
            scroll: true,
        });
    });
}

window.aa_pe_sort = function aa_pe_sort() {
    var page = jQuery('#aa-up-parts').data('page');
    var sort = jQuery('#aa-up-parts').sortable('serialize', {
        key: 'part[]',
        attribute: 'data-part',
    });
    iu.ajax(null, '/ajax/peSort', 'page=' + page + '&' + sort);
}

window.aa_char_counter = function aa_char_counter() {
    var data;
    if (arguments[0] !== undefined) data = arguments[0];
    jQuery('#aa-counter').text(data.characters);
}

window.aa_char_counter_plain = function aa_char_counter_plain(obj) {
    var words = 0,
        characters = 0,
        spaces = 0;
    var html = obj.value;
    var text = html.replace(/<\/(.*?)>/gi, ' ');
    text = text.replace(/<(.*?)>/gi, '');
    text = text.replace(/\t/gi, '');
    text = text.replace(/\n/gi, '');
    text = text.replace(/\r/gi, '');
    text = $.trim(text);

    if (text !== '') {
        var arrWords = text.split(/\s+/);
        var arrSpaces = text.match(/\s/g);

        if (arrWords) words = arrWords.length;
        if (arrSpaces) spaces = arrSpaces.length;

        characters = text.length;
    }

    aa_char_counter({ words: words, characters: characters, spaces: spaces });
}

window.aa_share = function aa_share(service, url, title, comment) {
    if (arguments[1] === undefined || arguments[1].startsWith('javascript')) {
        url = window.location.toString();
    }

    if (arguments[2] === undefined) {
        title = document.title;
    }

    if (arguments[3] === undefined) {
        comment = '#рекомендую_инфоурок';
    }

    if (arguments[0] === undefined) {
        jQuery('#aa-share-buffer-link').hide();
        jQuery('#aa-share-buffer-input').show().select();
        return;
    }

    var hashtag = function () {
        if (window.location.pathname.split('/')[1] != 'user') {
            return '&hashtag={hashtag}&quote={comment}';
        }
        return '';
    };
    var protocol = location.protocol === 'https:' ? 'https:' : 'http:';
    /**
     * Buttons
     */
    var services = {
        fb: {
            popupUrl: 'https://www.facebook.com/sharer/sharer.php?u={url}&quote={comment}',
            popupWidth: 600,
            popupHeight: 500,
        },
        tw: {
            popupUrl: 'https://twitter.com/intent/tweet?url={url}&text={title}',
            popupWidth: 600,
            popupHeight: 450,
        },
        mr: {
            popupUrl: protocol + '//connect.mail.ru/share?share_url={url}&title={title}',
            popupWidth: 550,
            popupHeight: 360,
        },
        vk: {
            popupUrl: protocol + '//vk.com/share.php?url={url}&title={title}&comment={comment}',
            popupWidth: 550,
            popupHeight: 330,
        },
        ok: {
            popupUrl: protocol + '//connect.ok.ru/offer?url={url}',
            popupWidth: 550,
            popupHeight: 360,
        },
        tg: {
            popupUrl: 'https://t.me/share/url?url={url}&text={title}',
            popupWidth: 550,
            popupHeight: 360
        },
    };

    var params = services[service];
    var context = {
        url: url,
        title: title,
        comment: comment,
        hashtag: '#рекомендую_инфоурок',
    };
    var urlPopup = params.popupUrl.replace(/\{([^\}]+)\}/g, function (m, key) {
        // If key doesn't exists in the context we should keep template tag as is
        return key in context
            ? encodeURIComponent
                ? encodeURIComponent(context[key])
                : context[key]
            : m;
    });

    var left = Math.round(screen.width / 2 - params.popupWidth / 2);
    var top = 0;
    if (screen.height > params.popupHeight) {
        top = Math.round(screen.height / 3 - params.popupHeight / 2);
    }

    var win = window.open(
        urlPopup,
        'sl_' + service,
        'left=' +
            left +
            ',top=' +
            top +
            ',' +
            'width=' +
            params.popupWidth +
            ',height=' +
            params.popupHeight +
            ',personalbar=0,toolbar=0,scrollbars=1,resizable=1',
    );
    if (win) {
        win.focus();
    } else {
        location.href = urlPopup;
    }
    return false;
}

window.aa_olp_order = function aa_olp_order(count) {
    const initialPrice = olp_cost_tbl[0][1];
    let cost = olp_cost_tbl[0];
    let key;
    for (key in olp_cost_tbl) {
        if (olp_cost_tbl[key][0] > count) {
            break;
        }
        cost = olp_cost_tbl[key];
    }
    let next = false;
    if (olp_cost_tbl[key] !== undefined) next = olp_cost_tbl[key];
    return [cost, next, initialPrice];
}

window.aa_olp_calc = function aa_olp_calc(count) {
    const obj = jQuery('.aa-olp-order-i');
    if (count) {
        obj.val(count);
    } else {
        count = obj.val();
    }
    const [costA, nextA, initialPrice] = aa_olp_order(count);
    const costForOneNow = Number(costA[1]) + Number(costA[2]) + Number(costA[3]);

    jQuery('.calculator__table td').removeClass('current active');
    jQuery(".calculator__table td[data-count='" + costA[0] + "']").addClass('current active');

    const cashBack = Math.round(costA[1] * count * 0.05);
    const discount = count * initialPrice - costA[1] * count;

    jQuery('.calculator__rubles').html(
      (costA[1] * count).toString() + '&nbsp;' + olp_cost_curr,
    );
    if (discount === 0) {
      jQuery('.calculator__discount-ruble').hide();
      jQuery('.calculator__discount-percent span').hide();
    } else {
      jQuery('.calculator__discount-ruble').show().text(count * initialPrice + ' руб.');
      jQuery('.calculator__discount-percent span').show().text(Math.round(discount / (count * initialPrice) * 100) + '%');
    }

    $('.calculator__sale span').html(discount);
    $('.calculator__cash span').html(cashBack);
    $('.calculator__economy span').html(cashBack + discount);

    if (nextA !== false && nextA[0] > costA[0]) {
        // nextA[0] - кол-во учеников, с которого добавляется новая скидка
        // nextA[1] - стоимость за 1 ученика при покупке nextA[0] учеников
        var profit = nextA[0] * (costForOneNow - nextA[1]);

        jQuery('.aa-olp-calc-next1').html(nextA[0] - count);
        jQuery('#aa-olp-calc-next-contest').html(
            numerize(nextA[0] - count, ['{n} ученика', '{n} учеников', '{n} учеников']),
        );
        jQuery('.add-count').html(profit);
        jQuery('.calculator__members').show();
        jQuery('.s35-spring__modal-calc-members').show();
    } else {
        jQuery('.calculator__members').hide();
        jQuery('.s35-spring__modal-calc-members').hide();
    }
}

window.aa_olp_calc_pp = function aa_olp_calc_pp() {
    var obj = jQuery('.aa-olp-order-i');
    var count = parseInt(obj.val()) + 1;
    if (!count) count = 1;
    obj.val(count);
    aa_olp_calc();
}

window.aa_olp_calc_mm = function aa_olp_calc_mm() {
    var obj = jQuery('.aa-olp-order-i');
    var count = parseInt(obj.val()) - 1;
    if (!count) count = 1;
    if (count <= 0) count = 1;
    obj.val(count);
    aa_olp_calc();
}

window.aa_olp_pay_rk = function aa_olp_pay_rk(id) {
    jQuery('#' + id).submit();
}

window.aa_olp_tab_activate = function aa_olp_tab_activate(tab, id) {
    var callback = null;
    if (arguments[2] !== undefined) callback = arguments[2];
    if (typeof callback === 'function') callback();

    var $tab = jQuery('#' + id + ' div[data-tab="' + tab + '"]');
    if (!$tab.length) return;
    var container = $tab.parent();
    //todo: Иван. Оптимизировать и проверить работу заявок!!!
    container.find('>div>ul a').removeClass('active');
    container.find('>div>ul a[data-link="' + tab + '"]').addClass('active');
    container.find('>ul a').removeClass('active');
    container.find('>ul a[data-link="' + tab + '"]').addClass('active');
    container.children('div.view').hide();
    $tab.show();
}

window.aa_olp_answer = function aa_olp_answer(o, a, b) {
    var tbl = jQuery('#aa-olp-order-tab2-' + o + ' table.aa-answer');
    var a_count = parseInt(tbl.data('a'));
    var b_count = parseInt(tbl.data('b'));
    var vAnswer = '#OlpPupil_answer';

    if (arguments[3] !== undefined) vAnswer = '#OlpPupil' + parseInt(arguments[3]) + '_answer';

    tbl.find('td[data-a=' + a + ']').removeClass('a-otvet-grin');
    tbl.find('td[data-a=' + a + '][data-b=' + b + ']').addClass('a-otvet-grin');
    var answer_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    tbl.find('td.a-otvet-grin').each(function (indx) {
        var a = parseInt(jQuery(this).data('a'));
        var b = parseInt(jQuery(this).data('b'));
        answer_arr[a - 1] = b;
        return true;
    });

    jQuery('#aa-olp-order-tab2-' + o + ' ' + vAnswer).val(answer_arr.join(','));
}

window.aa_olp_clear_answer = function aa_olp_clear_answer(o) {
    var msg = 'Внести ответы';
    if (arguments[1] !== undefined) msg = arguments[1];

    var form = jQuery('form#aa-olp-order-tab2-' + o);
    var tab = jQuery('#aa-order-' + o + '-a');
    var vOption = new Option('Населенный пункт', '');
    form.find('.school_id_pupil').val(null);
    form.find('.school_id_teacher').val(null);
    form.find('#OlpPupil_answer').val(null);
    form.find('.school_full_name').val(null);
    form.find('.school_id_class option:first').attr('selected', 'selected');
    //form.find(".school_id_region option:first").attr("selected", "selected");
    //form.find(".school_id_city").empty();
    //form.find(".school_id_city").append($(vOption));
    form.find('table.aa-answer td').removeClass('a-otvet-grin');
    form.find('.school_full_name').prop('disabled', false);
    form.find('.school_id_class').prop('disabled', false);
    form.find('.school_id_school').val(null).prop('disabled', false);
    form.find('.school_school').val(null).prop('disabled', false);

    form.find('.aa-parent-email').hide();
    form.find('.aa-clear-school').hide();
    form.find('.school_teacher_name').val(null).prop('disabled', false);
    if (parseInt(tab.data('c'))) tab.find('a[data-link=tab2-1]').text(msg);
    else tab.find('a[data-link=tab2-1]').hide();
}

window.aa_olp_edit_answer = function aa_olp_edit_answer(e, url, o, id) {
    var msg = 'Изменить ответы';
    if (arguments[4] !== undefined) msg = arguments[4];

    iu.ajax(e, url, { id: id }, function (data) {
        if (data.error === undefined) {
            jQuery('#aa-order-' + o + '-a a[data-link=tab2-1]')
                .text(msg)
                .show();
            aa_olp_tab_activate('tab2-1', 'aa-order-' + o + '-a');
        }
    });
}

window.aa_olp_new_order = function aa_olp_new_order(e, url) {
    jQuery('#aa-olp-inp-order-dd').val('');
    jQuery('#aa-olp-dyn').addClass('aa-load1');

    iu.ajax(e, url, {}, function (data) {
        jQuery('#aa-olp-dyn').removeClass('aa-load1');
    });
}

window.aa_olp_result = function aa_olp_result(e, o, id, url) {
    var r_obj = jQuery('#aa-order-' + o + '-b #aa-order-pupil-' + id + ' .aa-result');
    if (r_obj.length) {
        jQuery('#aa-order-' + o + '-b .aa-result').remove();
        jQuery('#aa-order-' + o + '-b .aa-result-link').text('результаты и награды');
        jQuery('#aa-order-' + o + '-b .a-open').removeClass('a-open-border');
    } else {
        jQuery('#aa-order-' + o + '-b .aa-result').remove();
        jQuery('#aa-order-' + o + '-b .aa-result-link').text('результаты и награды');
        jQuery('#aa-order-' + o + '-b .a-open').removeClass('a-open-border');
        iu.ajax(e, url, { id: id }, function (data) {
            if (data.error === undefined) {
                jQuery('#aa-order-' + o + '-b #aa-order-pupil-' + id + ' .aa-result-link').text(
                    'скрыть',
                );
                jQuery('#aa-order-' + o + '-b #aa-order-pupil-' + id).append(data.data.html);
                jQuery('#aa-order-' + o + '-b #aa-order-pupil-' + id).addClass('a-open-border');
                moveToAnchorDiv('#aa-order-pupil-' + id, '#aa-order-' + o + '-b>div');
            }
        });
    }
}

window.aa_olp_cancel_order = function aa_olp_cancel_order() {
    jQuery('#aa-olp-dyn').html('');
    jQuery('#aa-olp-inp-order-new').show();
}

window.aa_olp_std_order_form = function aa_olp_std_order_form(e, url) {
    if (aa_ext_dwnld_url != null) {
        window.olp_is_new = false;
        jQuery('#aa-dwld-timer-conteiner').hide();
        location.href = aa_ext_dwnld_url;
        aa_ext_dwnld_url = null;
        if (aa_ext_dwnld_timer != null) clearInterval(aa_ext_dwnld_timer);
        aa_ext_dwnld_timer = null;
    }
    iu.ajax(e, url, '#aa-olp-order', function (data) {
        if (data.error === undefined) {
            window.olp_is_new = false;
        }
    });
}

window.aa_olp_std_cancel_order = function aa_olp_std_cancel_order() {
    window.olp_is_new = false;
    olp_cost_tbl = olp_cost_tbl_const;
    jQuery('.aa-olp-order-i').val('10');
    jQuery('.aa_dd_id_olp_olympic').attr('disabled', false).val('');
    jQuery('.id_olp_olympic').val('');
    iu.popup(
        'Заявка была отменена. Чтобы подать новую заявку, выберите предмет из выпадающего списка, укажите количество учеников и нажмите на кнопку «Отправить заявку».',
    );
    jQuery('#aa-link-cancel').hide();
    aa_olp_calc();
}

window.aa_olp_select_olympic = function aa_olp_select_olympic(e, url) {
    jQuery('#aa-olp-dyn-calc').addClass('aa-load1');

    var id = jQuery(e.target).val();
    if (!parseInt(id)) {
        jQuery('#aa-olp-dyn-calc').html('').removeClass('aa-load1');
        return;
    }

    iu.ajax(e, url, { id: id }, function (data) {
        if (data.error === undefined) {
            jQuery('.id_olp_olympic').val(id);
            jQuery(e.target).attr('disabled', 'disabled');
        }
        jQuery('#aa-olp-dyn-calc').removeClass('aa-load1');
    });
}

window.aa_olp_std_select_olympic = function aa_olp_std_select_olympic(e, url) {
    var tar = e.target || e.srcElement;
    var id = jQuery(tar).val();
    if (!parseInt(id)) {
        return;
    }

    $('.aa_dd_id_olp_olympic option[value=' + id + ']').prop('selected', true);
    jQuery('.id_olp_olympic').val(id);
    jQuery('#aa-link-cancel').show();
    iu.ajax(null, url, { id: id }, function (data) {
        if (data.error !== undefined) {
        } else {
            window.olp_is_new = true;
        }
    });
}

window.aa_olp_select_order = function aa_olp_select_order(e, url) {
    jQuery('#aa-olp-dyn').addClass('aa-load1');

    var id = jQuery(e.target).val();
    if (!parseInt(id)) {
        jQuery('#aa-olp-dyn').html('').removeClass('aa-load1');
        return;
    }

    iu.ajax(e, url, { id: id }, function (data) {
        jQuery('#aa-olp-dyn').removeClass('aa-load1');
    });
    return false;
}

window.aa_tooltip = function aa_tooltip(e, m) {
    var obj = jQuery(e.target);
    //    jQuery(".a-tooltip").remove();
    jQuery("<span class='a-tooltip' style='display: none'></span>")
        .html(m)
        .appendTo('body')
        .css('top', e.pageY - 10 + 'px')
        .css('left', e.pageX + 20 + 'px')
        .fadeIn('slow');
}

window.aa_tooltip_hide = function aa_tooltip_hide(e) {
    jQuery('.a-tooltip').remove();
}

window.aa_olp_order_del = function aa_olp_order_del(e, id, url) {
    var confirm = true;
    if (arguments[3] !== undefined) confirm = arguments[3];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить заявку',
            'Обратите внимание, что после удаления заявки Вы не сможете ее восстановить. Вы действительно хотите удалить заявку?',
            function () {
                aa_olp_order_del(e, id, url, false);
            },
        );
        return;
    }

    iu.ajax(e, url, { id: id, tab: 'tab0' });

    return false;
}

window.aa_olp_pupil_del = function aa_olp_pupil_del(e, id, url) {
    var confirm = true;
    if (arguments[3] !== undefined) confirm = arguments[3];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить ученика',
            'Внимание!<br /> Если Вы удалите данного ученика из заявки, то он больше не сможет принять участие в финальном туре олимпиад. Вы не сможете добавить данного ученика заново. Действительно удалить ученика из заявки? <br /><br />*при удалении ученика стоимость заявки пересчитается',
            function () {
                aa_olp_pupil_del(e, id, url, false);
            },
        );
        return;
    }

    iu.ajax(e, url, { id: id });

    return false;
}

window.aa_olp_helper = function aa_olp_helper(id) {
    var obj = jQuery('#' + id);
    if (!obj.length) return;

    if (obj.find('span').eq(0).text()) {
        obj.find('a').eq(1).html('<i class="icon icon-edit"></i>');
    } else {
        obj.find('a').eq(1).html('<i class="a-plusss"></i>Добавить помощника');
        //        obj.find('a').eq(1).remove();
    }
}

window.aa_inline_edit = function aa_inline_edit(e, id) {
    var tpl = tpl_olp_inline_input;
    if (arguments[2] !== undefined) tpl = arguments[2];

    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('span').eq(0).text();
    var src = tpl;
    var idd = id.split('-');
    src = src.replace(/\{id\}/gi, id);
    src = src.replace(/\{val\}/gi, escapeHtml(val));
    src = src.replace(/\{idd\}/gi, idd[2]);

    obj.html(src);
}

window.aa_inline_save = function aa_inline_save(e, id) {
    var callback = null,
        tpl = tpl_olp_inline_span;
    if (arguments[2] !== undefined) tpl = arguments[2];
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('input').eq(0).val();
    var idd = id.split('-');

    iu.ajax(e, '/ajax/inlineSave', { id: id, val: val }, function (data) {
        if (data.data !== undefined) {
            if (data.data.val !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{val\}/gi, data.data.val);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);
            }
            if (data.data.noEdit !== undefined) {
                obj.find('span:last').remove();
            }
        }
    });
}

window.aa_in_cart = function aa_in_cart(id) {
    jQuery('#aa-out-cart>div[data-id=' + id + ']')
        .appendTo(jQuery('#aa-in-cart'))
        .removeClass('a-dopinfo-fechka')
        .addClass('a-info-fechka');
    aa_cart_recalc();
}

window.aa_out_cart = function aa_out_cart(id) {
    jQuery('#aa-in-cart>div[data-id=' + id + ']')
        .appendTo(jQuery('#aa-out-cart'))
        .removeClass('a-info-fechka')
        .addClass('a-dopinfo-fechka');
    aa_cart_recalc();
}

window.aa_cart_recalc = function aa_cart_recalc() {
    var t_ids = '';
    var t_sum = 0;
    var t_size = 0;
    jQuery('#aa-in-cart>div').each(function (indx) {
        if (t_ids) t_ids += ',';
        t_ids += jQuery(this).data('id');
        t_sum += jQuery(this).data('cost');
        t_size += jQuery(this).data('size');
        return true;
    });
    jQuery('#ProdOrder_product_ids').val(t_ids);
    jQuery('#aa-prod-sum').text(t_sum);

    if (typeof aa_user_balance != 'undefined') {
        jQuery('#aa-prod-file-size').text(formatSize(t_size));
        if (aa_user_balance < t_sum) {
            jQuery('#aa-prod-pay-bill-input').hide();
            jQuery('#Order_payment_2').prop('checked', false);
            jQuery('#Order_payment_3').prop('checked', true);
        } else {
            jQuery('#aa-prod-pay-bill-input').show();
        }
    }

    var $elements = jQuery('#aa-out-cart>div');
    var $target = jQuery('#aa-out-cart');
    $elements.sort(function (a, b) {
        var an = parseInt(jQuery(a).data('order')),
            bn = parseInt(jQuery(b).data('order'));
        return an - bn;
    });
    $elements.detach().appendTo($target);

    jQuery('#aa-in-cart .aa-prod-add').hide();
    jQuery('#aa-in-cart .aa-prod-del').show();
    jQuery('#aa-out-cart .aa-prod-add').show();
    jQuery('#aa-out-cart .aa-prod-del').hide();

    if (jQuery('#aa-out-cart>div').length) jQuery('#aa-prod-upsell').show();
    else jQuery('#aa-prod-upsell').hide();
}

window.aa_olp_hide_orang_li = function aa_olp_hide_orang_li() {
    var c_name = '_aa_olp_o_li';
    if (arguments[0] !== undefined) c_name = arguments[0];

    var val = $.cookie(c_name);

    if (val) return true;
    jQuery('.aa-olp-o-li').removeClass('a-orang-blink');
    $.cookie(c_name, 1, { expires: 200, path: '/' });
}

window.d_olp_hide_orang_li = function d_olp_hide_orang_li(prm) {
    var vBlock = jQuery('div#aa-order-' + prm);
    $.cookie('d-blink-' + prm, 1, { expires: 200, path: '/' });
    vBlock.find('li.a-orang-blink').removeClass('a-orang-blink');
}

window.aa_timer = function aa_timer(date, update) {
    var callback = null;
    if (arguments[2] !== undefined) callback = arguments[2];

    /* ДАТА ПОНИЖЕНИЯ СКИДКИ "Tue Sep 01 2015 00:00:00 GMT+0300" */
    var t1 = new Date(date).getTime() / 1000;
    var t2 = new Date().getTime() / 1000;
    var fiveMinutes = t1 - t2;
    //    fiveMinutes = 0;

    console.log(date, t1, t2, fiveMinutes);

    var start = Date.now(),
        display = $(update),
        diff,
        minutes,
        seconds,
        hours,
        days,
        interval;

    function timer() {
        // get the number of seconds that have elapsed since
        // startTimer() was called
        diff = fiveMinutes - (((Date.now() - start) / 1000) | 0);

        // does the same job as parseInt truncates the float
        days = (diff / 86400) | 0;
        hours = (diff / 3600) % 24 | 0;
        minutes = (diff / 60) % 60 | 0;
        seconds = diff % 60 | 0;

        days = days < 10 ? days : days;
        hours = hours < 10 ? hours : hours;
        minutes = minutes < 10 ? minutes : minutes;
        seconds = seconds < 10 ? seconds : seconds;

        display.text(
            (days > 0 ? days + ' д. ' : '') + hours + ' ч. ' + minutes + ' м. ' + seconds + ' с.',
        );

        if (diff <= 0) {
            // add one second so that the count down starts at the full duration
            // example 05:00 not 04:59
            clearInterval(interval);
            fiveMinutes = 0;
            if (typeof callback === 'function') callback(update);
        }
    }

    if (fiveMinutes <= 0) {
        fiveMinutes = 0;
        if (typeof callback === 'function') callback(update);
    } else {
        interval = setInterval(timer, 1000);
    }
}

window.aa_timer2 = function aa_timer2(sec, update) {
    var callback = null;
    if (arguments[2] !== undefined) callback = arguments[2];
    var display = $(update),
        interval;

    function timer() {
        sec--;
        display.text(sec);
        if (sec <= 0) {
            clearInterval(interval);
            aa_ext_dwnld_timer = null;
            if (typeof callback === 'function') callback(update);
        }
    }

    if (sec) {
        interval = setInterval(timer, 1000);
        aa_ext_dwnld_timer = interval;
    } else {
        if (typeof callback === 'function') callback(update);
    }
}

window.aa_video_flash_popup = function aa_video_flash_popup(obj) {
    jQuery(obj).parent().parent().find('.popup').fadeIn(100);
}

window.formatSize = function formatSize(length) {
    var i = 0,
        type = ['Кб', 'Мб', 'Гб', 'Тб', 'Пб'];
    while ((length / 1000) | 0 && i < type.length - 1) {
        length /= 1024;
        i++;
    }
    return length.toFixed(2) + ' ' + type[i];
}

window.addViewDocCnt = function addViewDocCnt(el) {
    var vElem = jQuery(el).parent().parent().find('a.kr-button');
    var vHref = vElem.attr('href');

    var vExp = new RegExp('(view=0)', 'i');
    var vMatch = vHref.match(vExp);

    if (vMatch) {
        vHref = vHref.replace('view=0', 'view=1');
    } else {
        vHref = vHref.replace('view=1', 'view=0');
    }
    vElem.attr({ href: vHref });
}

window.escapeHtml = function escapeHtml(text) {
    return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}

window.videoLessonsMenu = function videoLessonsMenu(event) {
    var target = $(event.target),
        targerUl,
        obj,
        timer;
    if (target[0].tagName.toLowerCase() !== 'a') return false;

    targerUl = target.parent().find('> ul');
    if (targerUl.length) {
        obj = $('.dr-cat-less > li');

        if (!target.parent().hasClass('dr-active')) {
            for (var i = 0; i < obj.length; i++) {
                if ($(obj[i]).hasClass('dr-active')) {
                    $(obj[i]).find('> ul li').fadeOut(100);
                    $(obj[i]).removeClass('dr-active');
                }
            }
            timer = setTimeout(function () {
                target.parent().toggleClass('dr-active');
                targerUl.find('li').toggle(250);
                clearTimeout(timer);
            }, 100);
        } else {
            target.parent().toggleClass('dr-active');
            targerUl.find('li').toggle(250);
        }
    }
    return false;
}

window.videoLessonsMenuToggle = function videoLessonsMenuToggle() {
    $('.dr-cat-less').toggleClass('dr-show');
}

window.ag_char_counter_plain = function ag_char_counter_plain(idCount, idSet) {
    //jQuery('#ag-charC-'+id).html('').html(jQuery('#ag-anot-'+id).val().length);
    var words = 0,
        characters = 0,
        spaces = 0;
    var html = idCount.value;
    var text = html.replace(/<\/(.*?)>/gi, ' ');
    text = text.replace(/<(.*?)>/gi, '');
    text = text.replace(/\t/gi, '');
    text = text.replace(/\n/gi, '');
    text = text.replace(/\r/gi, '');
    text = $.trim(text);

    if (text !== '') {
        var arrWords = text.split(/\s+/);
        var arrSpaces = text.match(/\s/g);

        if (arrWords) words = arrWords.length;
        if (arrSpaces) spaces = arrSpaces.length;

        characters = text.length;
    }
    ag_char_counter_set(
        idSet,
        { words: words, characters: characters, spaces: spaces },
        'characters',
    );
    return { words: words, characters: characters, spaces: spaces };
}

window.ag_char_counter_set = function ag_char_counter_set(id) {
    var data;
    var type;
    if (arguments[1] !== undefined) data = arguments[1];
    if (arguments[2] !== undefined) type = arguments[2];
    jQuery(id).text(data[type]);
}

window.ag_pub_an_validate = function ag_pub_an_validate() {
    var err = 0;
    var allTA = jQuery('#addAnnotation textarea');
    for (var i = 0; i < allTA.length; i++) {
        var idC = parseInt(allTA[i].id.replace('ag-anot-', ''));
        var vals = ag_char_counter_plain(allTA[i], '#ag-charC-' + idC);
        if (vals.characters > 500) {
            err++;
            jQuery('#' + allTA[i].id).prop('class', 'error');
            jQuery('#ag-charC-' + idC)
                .parent()
                .prop('class', 'dr-red');
        }
        if (vals.characters < 250) {
            err++;
            jQuery('#' + allTA[i].id).prop('class', 'error');
            jQuery('#ag-charC-' + idC)
                .parent()
                .prop('class', 'dr-red');
        }
        if (vals.characters <= 500 && vals.characters >= 250) {
            jQuery('#' + allTA[i].id).prop('class', '');
            jQuery('#ag-charC-' + idC)
                .parent()
                .prop('class', '');
        }
        //console.log(vals);
    }
    if (err > 0) {
        //jQuery("#addAnnotation [type=submit]").prop( "disabled", true );
        jQuery('.errorSummary')
            .show()
            .text('Описание к материалам должно быть от 250 до 500 символов');
    } else {
        //jQuery("#addAnnotation [type=submit]").prop( "disabled", false );
        jQuery('.errorSummary').hide().text('');
    }
    //console.log(err);
}

var cssFix = (function () {
    var u = navigator.userAgent.toLowerCase(),
        is = function (t) {
            return u.indexOf(t) != -1;
        };
    $('html').addClass(
        [
            !/opera|webtv/i.test(u) && /msie (\d)/.test(u)
                ? 'no-svg ie ie' + RegExp.$1
                : is('firefox/2')
                ? 'gecko ff2'
                : is('firefox/3')
                ? 'gecko ff3'
                : is('gecko/')
                ? 'gecko'
                : is('opera/9')
                ? 'opera opera9'
                : /opera (\d)/.test(u)
                ? 'opera opera' + RegExp.$1
                : is('konqueror')
                ? 'konqueror'
                : is('applewebkit/')
                ? 'webkit safari'
                : is('mozilla/')
                ? 'gecko'
                : '',
            is('x11') || is('linux') ? ' linux' : is('mac') ? ' mac' : is('win') ? ' win' : '',
        ].join(''),
    );
})();

window.setSchoolCountry = function setSchoolCountry(el) {
    if (el.value == 183) {
        jQuery('#d-school-region').prop('disabled', 'disabled');
        jQuery('#d-school-region').val('');
        jQuery('#d-school-city').prop('disabled', 'disabled');
        jQuery('#d-school-city').val('');
        jQuery('#d-school-school').prop('disabled', 'disabled');
        jQuery('#d-school-school').val('');
        jQuery('#d-school-school').val('');

        jQuery('#d-school-region-btn').show();
        jQuery('#d-school-city-btn').show();
        jQuery('#d-school-school-btn').show();
    } else {
        jQuery('#d-school-region').prop('disabled', false);
        jQuery('#d-school-city').prop('disabled', false);
        jQuery('#d-school-school').prop('disabled', false);

        jQuery('#d-school-region-btn').hide();
        jQuery('#d-school-city-btn').hide();
        jQuery('#d-school-school-btn').hide();
    }
}

window.getSchoolRegion = function getSchoolRegion() {
    iu.ajax(null, '/ajax/getRegion', null, function (data) {
        jQuery('#d-school-region-list').html(data.data);
    });
}

window.getSchoolCity = function getSchoolCity() {
    var vIdRegion = jQuery('#d-school-region-id').val();
    iu.ajax(null, '/ajax/getCity?id_region=' + vIdRegion, null, function (data) {
        jQuery('#d-school-city-list').html(data.data);
    });
}

window.agPublicationSum = function agPublicationSum() {
    //!= 'checked'
    var cost, costP, costCop, costFull;

    cost = jQuery('#cost').val();
    costP = jQuery('#cost_printed').val();
    costCop = jQuery('#cost_copy').val();
    costFull = jQuery('#cost_full').val();

    var dop = jQuery('#Publication_printed').prop('checked');
    if (dop) {
        jQuery('#ag-printed').html('1');
        var countC = jQuery('#Publication_printed_copies').val();
        if (countC.length < 1) countC = 0;
        jQuery('#ag-printed_copies').html(parseInt(countC));
        jQuery('#dopCount').html(parseInt(countC));
        var full = parseInt(costP) + parseInt(countC) * parseInt(costCop) + parseInt(costFull);
        jQuery('#ag-publ-full-cost').html(full);
        jQuery('.dopcost').show();
    } else {
        jQuery('#ag-printed').html('0');
        jQuery('.dopcost').hide();
        jQuery('#ag-publ-full-cost').html(costFull);
    }
}

window.ag_Pub_pp = function ag_Pub_pp() {
    var obj = jQuery('#Publication_printed_copies');
    var count = parseInt(obj.val()) + 1;
    if (!count) count = 1;
    obj.val(count);
    agPublicationSum();
}

window.ag_Pub_mm = function ag_Pub_mm() {
    var obj = jQuery('#Publication_printed_copies');
    var count = parseInt(obj.val()) - 1;
    if (!count) count = 0;
    if (count <= 0) count = 0;
    obj.val(count);
    agPublicationSum();
}

window.aa_order_get_school = function aa_order_get_school(c) {
    var container = jQuery(c);

    var vRegion = container.find('.school_id_region').val();
    var vSchool = container.find('.school_school').val();
    if (vSchool.length >= 1 /* && vRegion*/) {
        iu.ajax(
            null,
            '/konkurs/s5/getSchool',
            { id_region: vRegion, school: vSchool, c: c },
            function (data) {
                if (data.error !== undefined) {
                    var container = jQuery(c);
                    container.find('.school_school').val('');
                    return false;
                }

                if (data.data !== undefined) {
                    var list = jQuery('#aa-school-global-list');
                    if (!data.data.val) {
                        list.remove();
                        return;
                    }
                    var container = jQuery(data.data.c);
                    var vSchool = container.find('.school_school');
                    if (!list.length) {
                        list = jQuery('<span />', {
                            id: 'aa-school-global-list',
                        }).appendTo('body');
                        //console.log(list);
                    }

                    var x = vSchool.offset().left;
                    var y = vSchool.offset().top + vSchool.outerHeight();
                    list.attr('style', 'position: absolute; top: ' + y + 'px; left: ' + x + 'px; ');
                    list.html(data.data.val);
                }
            },
        );
    }
}

window.aa_order_set_school = function aa_order_set_school(c, id, name) {
    var container = jQuery(c);

    container.find('.school_id_school').val(id);
    container.find('.school_school').val(name).prop('readonly', true);

    jQuery('#aa-school-global-list').remove();
    container.find('.school_clear').show();
}

window.aa_order_clear_school = function aa_order_clear_school(c) {
    var container = jQuery(c);

    container.find('.school_id_school').val('');
    container.find('.school_school').val('').prop('readonly', false);

    jQuery('#aa-school-global-list').remove();
    container.find('.school_clear').hide();
}

window.aa_order_set_full_name = function aa_order_set_full_name(o, name, school, id) {
    var form = jQuery('form#aa-olp-order-tab2-' + o);
    var vId = '';
    if (id !== undefined) vId = id;

    form.find('#OlpPupil' + vId + '_teacher_name').val(name);
    form.find('.d-order-full-name').html('');
}

window.aa_set_region = function aa_set_region(e, val) {
    var vVal = $('#Step2Form_region_id').val();
    iu.ajax(e, '/site/getArea', { id: vVal }, function (data) {
        jQuery('#aa-sel-city').show();
        //aa_clearSchool();
        aa_city = '';
        aa_setContactUrl();
    });
}

window.aa_setContactUrl = function aa_setContactUrl(o) {
    var form = jQuery('form#aa-school-global-' + o);

    var aa_country = form.find('.school_id_region option:selected').text();

    var protocol = location.protocol === 'https:' ? 'https:' : 'http:';
    var myOtherUrl =
        protocol + '//' + document.domain + '/site/final?co=' + encodeURIComponent(aa_country);
    jQuery('.aa-contact-link').attr('href', myOtherUrl);
}

window.aa_setContactUrl2 = function aa_setContactUrl2(o) {
    var form = jQuery('form#aa-olp-order-tab2-' + o);

    var aa_country = form.find('.school_id_region option:selected').text();

    var protocol = location.protocol === 'https:' ? 'https:' : 'http:';
    var myOtherUrl =
        protocol + '//' + document.domain + '/site/final?co=' + encodeURIComponent(aa_country);
    jQuery('.aa-contact-link').attr('href', myOtherUrl);
}

window.dr_olimp_pupil_show_results = function dr_olimp_pupil_show_results(event) {
    var results = $(event.target),
        wrap = $(event.target),
        i = 0;

    while (results[0].tagName.toLowerCase() !== 'body') {
        results = results.parent();
        if (results.hasClass('dr-olimp-results')) break;
    }
    while (wrap[0].tagName.toLowerCase() !== 'body') {
        wrap = wrap.parent();
        if (wrap.hasClass('a-olympic')) break;
    }

    results = results.find('.dr-pupil-answers');
    if (results.hasClass('open')) {
        results.removeClass('open').slideUp();
        return false;
    } else {
        wrap = wrap.find('.dr-pupil-answers');
        for (i = 0; i < wrap.length; i++) {
            $(wrap[i]).removeClass('open').slideUp();
        }

        results.addClass('open').slideDown();
    }
}

window.drPushGlobal = function drPushGlobal() {
    $('#dr-push-global-wrap').slideUp();
}

window.dSchoolTitle = function dSchoolTitle(id) {
    var form = jQuery(id);
    var schoolName = form.find('.school_school').val();
    if (schoolName) form.find('.d-title').html(schoolName);
    return false;
}

window.ag_kurs_order_del = function ag_kurs_order_del(e, id, url) {
    var confirm = true;
    if (arguments[3] !== undefined) confirm = arguments[3];
    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить заявку',
            'Обратите внимание, что после удаления заявки Вы не сможете ее восстановить. Вы действительно хотите удалить заявку на курсы?',
            function () {
                ag_kurs_order_del(e, id, url, false);
            },
        );
        return;
    }
    //iu.ajax(e, url,{id:id,tab:'tab0'});
    iu.ajax(e, url, { id: id });

    return false;
}

window.ag_camp_order_del = function ag_camp_order_del(e, id, url) {
    var confirm = true;

    if (arguments[3] !== undefined) {
        confirm = arguments[3];
    }

    if (confirm) {
        iu.dlg.confirm(
            e,
            'Удалить заявку',
            'Обратите внимание, что после удаления заявки Вы не сможете ее восстановить. Вы действительно хотите удалить заявку?',
            function () {
                ag_camp_order_del(e, id, url, false);
            },
        );
        return;
    }

    iu.ajax(e, url, { id: id });

    return false;
}

window.ag_camp_region_get = function ag_camp_region_get(e, id) {
    if (parseInt(id) > 0) {
        var url = '/camp/GetMyRegion/' + id;
        //jQuery('#CampsShifts_region');
        jQuery('#CampsShifts_region').attr('disabled', true).addClass('a-load-button').empty();
        iu.ajax(null, url, {});
    } else {
        jQuery('#CampsShifts_region').empty();
    }
}

window.d_loadFile_kurs = function d_loadFile_kurs(prm) {
    var vForm = '#aa-' + prm + '-form';
    var vUrlFile = d_loadFile_url + '/upload.html';

    aa_init_upload(vForm, vUrlFile, prm, function (data) {
        console.log(data);
        //        iu.ajax(null, '/kursyTested/loadFile',{id:prm, data:data}, function(result) {
        //            jQuery('#d-theme-'+prm).val(result.data);
        //        });
    });
}

window.course_column_cng = function course_column_cng(id, tpl) {
    var obj = $('#' + id);
    if (!obj.length) return;

    var val = obj.find('strong').eq(0).text();
    var src = tpl;
    var idd = id.split('-');
    src = src.replace(/\{id\}/gi, id);
    src = src.replace(/\{val\}/gi, escapeHtml(val));
    src = src.replace(/\{idd\}/gi, idd[2]);

    obj.html(src);
}

window.course_work_title_save = function course_work_title_save(e, id, tpl) {
    var callback = null;
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = $('#' + id);
    if (!obj.length) return;

    var val = obj.find('input').eq(0).val().trim();
    var idd = id.split('-');
    if (val.length < 1) {
        iu.popup('поле не должно быть пустым');
        return;
    }

    iu.ajax(e, '/backOffice/cngWorkTitle', { id: id, val: val }, function (data) {
        if (data.data !== undefined) {
            if (data.data.val !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{val\}/gi, data.data.val);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);
            }
        }
    });
}

window.course_work_place_save = function course_work_place_save(e, id, tpl) {
    var callback = null;
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = $('#' + id);
    if (!obj.length) return;

    var val = obj.find('input').eq(0).val().trim();
    var idd = id.split('-');
    if (val.length < 1) {
        iu.popup('поле не должно быть пустым');
        return;
    }

    iu.ajax(e, '/backOffice/cngWorkPlace', { id: id, val: val }, function (data) {
        if (data.data !== undefined) {
            if (data.data.val !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{val\}/gi, data.data.val);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);
            }
        }
    });
}

window.d_postitem_cng = function d_postitem_cng(e, id, tpl) {
    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('span').eq(0).text();
    var src = tpl;
    var idd = id.split('-');
    src = src.replace(/\{id\}/gi, id);
    src = src.replace(/\{val\}/gi, escapeHtml(val));
    src = src.replace(/\{idd\}/gi, idd[2]);

    obj.html(src);
}

window.d_address_cng = function d_address_cng(e, id, tpl) {
    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var region = obj.find('.d-region').text();
    var index = obj.find('.d-postindex').text();
    var city = obj.find('.d-city').text();
    var street = obj.find('.d-street').text();
    var house = obj.find('.d-house').text();
    var korpus = obj.find('.d-korpus').text();
    var flat = obj.find('.d-flat').text();

    house = house.replace('д. ', '');
    korpus = korpus.replace('к. ', '');
    flat = flat.replace('кв. ', '');

    var src = tpl;
    var idd = id.split('-');
    src = src.replace(/\{id\}/gi, id);
    src = src.replace(/\{region\}/gi, escapeHtml(region));
    src = src.replace(/\{index\}/gi, escapeHtml(index));
    src = src.replace(/\{city\}/gi, escapeHtml(city));
    src = src.replace(/\{street\}/gi, escapeHtml(street));
    src = src.replace(/\{house\}/gi, escapeHtml(house));
    src = src.replace(/\{korpus\}/gi, escapeHtml(korpus));
    src = src.replace(/\{flat\}/gi, escapeHtml(flat));
    src = src.replace(/\{idd\}/gi, idd[2]);

    obj.html(src);
}

window.d_name_d_cng = function d_name_d_cng(e, id, tpl) {
    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('strong').eq(0).text();
    var src = tpl;
    var idd = id.split('-');
    src = src.replace(/\{id\}/gi, id);
    src = src.replace(/\{val\}/gi, escapeHtml(val));
    src = src.replace(/\{idd\}/gi, idd[2]);

    obj.html(src);
}

window.d_uo_cng = function d_uo_cng(e, id, tpl) {
    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('span').eq(0).text();
    var src = tpl;
    var idd = id.split('-');
    src = src.replace(/\{id\}/gi, id);
    src = src.replace(/\{val\}/gi, escapeHtml(val));
    src = src.replace(/\{idd\}/gi, idd[2]);

    obj.html(src);
}

window.d_postitem_save = function d_postitem_save(e, id, tpl) {
    var callback = null;
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('input').eq(0).val().trim();
    var idd = id.split('-');
    if (val.length < 1) {
        iu.popup('поле не должно быть пустым');
        return;
    }
    iu.ajax(e, '/backOffice/cngPostalItem', { id: id, val: val }, function (data) {
        if (data.data !== undefined) {
            if (data.data.val !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{val\}/gi, data.data.val);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);
            }
            if (data.data.noEdit !== undefined) {
                obj.find('span:last').remove();
            }
        }
    });
}

window.d_uoitem_save = function d_uoitem_save(e, id, tpl) {
    var callback = null;
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('input').eq(0).val();
    var idd = id.split('-');

    iu.ajax(e, '/kursyTested/cngUo', { id: id, uo: val }, function (data) {
        if (data.data !== undefined) {
            if (data.data.val !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{val\}/gi, data.data.val);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);
            }
            if (data.data.noEdit !== undefined) {
                obj.find('span:last').remove();
            }
        }
    });
}

window.d_address_save = function d_address_save(e, id, tpl) {
    var callback = null;
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var region = obj.find('[name=region]').val();
    var index = obj.find('[name=postindex]').val();
    var city = obj.find('[name=city]').val();
    var street = obj.find('[name=street]').val();
    var house = obj.find('[name=house]').val();
    var korpus = obj.find('[name=korpus]').val();
    var flat = obj.find('[name=flat]').val();
    var idd = id.split('-');

    iu.ajax(
        e,
        '/backOffice/cngAddress',
        {
            id: id,
            region: region,
            index: index,
            city: city,
            street: street,
            house: house,
            korpus: korpus,
            flat: flat,
        },
        function (data) {
            if (data.data !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{address\}/gi, data.data.address);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);

                iu.popup('Адрес сохранен!');
            }

            // if(data.data.noEdit !== undefined){
            //     obj.find('span:last').remove();
            // }
        },
    );
}

window.d_nameitem_d_save = function d_nameitem_d_save(e, id, tpl) {
    var callback = null;
    if (arguments[3] !== undefined) callback = arguments[3];

    var obj = jQuery('#' + id);
    if (!obj.length) return;

    var val = obj.find('input').eq(0).val();
    var idd = id.split('-');

    iu.ajax(e, '/backOffice/cngNameD', { id: id, val: val }, function (data) {
        if (data.data !== undefined) {
            if (data.data.val !== undefined) {
                var src = tpl;
                src = src.replace(/\{id\}/gi, id);
                src = src.replace(/\{val\}/gi, data.data.val);
                src = src.replace(/\{idd\}/gi, idd[2]);

                obj.html(src);
                if (typeof callback === 'function') callback(id);

                iu.popup('ФИО сохранено!');
            }
            if (data.data.noEdit !== undefined) {
                obj.find('span:last').remove();
            }
        }
    });
}

function aa_tutor_filter_range($obj, id) {
    $obj = jQuery($obj);
    var lo = jQuery('#aa-filter-lo-' + id).val();
    var hi = jQuery('#aa-filter-hi-' + id).val();
    lo = parseInt(lo);
    hi = parseInt(hi);
    if (!lo) lo = 0;
    if (!hi) hi = 0;
    if (!lo && !hi) {
        iu.popup.error('Необходимо указать диапазон');
        return false;
    }
    if (lo > hi) {
        hi = 0;
        jQuery('#aa-filter-hi-' + id).val('');
    }
    var href = $obj.attr('href');
    href = href.replace(/xhax/gi, lo);
    href = href.replace(/xhox/gi, hi);
    $obj.attr('href', href);
    return true;
}

var aa_interval;

window.d_timer = function d_timer(time, update) {
    if (aa_interval !== undefined) {
        clearInterval(aa_interval);
    }

    var callback = null;
    if (arguments[2] !== undefined) callback = arguments[2];

    var fiveMinutes = time;

    var start = Date.now(),
        display = $(update),
        diff,
        minutes,
        seconds,
        hours,
        days;

    function timer() {
        // get the number of seconds that have elapsed since
        // startTimer() was called
        diff = fiveMinutes - (((Date.now() - start) / 1000) | 0);

        // does the same job as parseInt truncates the float
        days = (diff / 86400) | 0;
        hours = (diff / 3600) % 24 | 0;
        minutes = (diff / 60) % 60 | 0;
        seconds = diff % 60 | 0;

        days = days < 10 ? days : days;
        hours = hours < 10 ? hours : hours;
        minutes = minutes < 10 ? minutes : minutes;
        seconds = seconds < 10 ? seconds : seconds;

        display.text(
            (days > 0 ? days + ' д. ' : '') +
                (hours > 0 ? hours + ' ч. ' : '') +
                minutes +
                ' м. ' +
                seconds +
                ' с.',
        );

        if (diff <= 0) {
            // add one second so that the count down starts at the full duration
            // example 05:00 not 04:59
            clearInterval(aa_interval);
            fiveMinutes = 0;
            if (typeof callback === 'function') callback(callback);
        }
    }

    if (fiveMinutes <= 0) {
        fiveMinutes = 0;
        if (typeof callback === 'function') callback(callback);
    } else {
        aa_interval = setInterval(timer, 1000);
    }
}

// Функция для разворота скрытого контента
window.showContent = function showContent(el) {
    var blockHeight = $('.kr-all-content').outerHeight();
    $(el).hide();
    $('.kr-all-content--hidden').css('height', blockHeight);
}

$(document).ready(function () {
    var menuBg = $('#header-menu').css({ top: 0, transition: 'none' });
    var header = $('.header');
    var margin = parseInt(header.css('margin-bottom'), 10) || 0;

    function update() {
        if ($(window).width() < 1231) {
            menuBg.removeClass('navigation--scroll').stop(true, true).css({ position: '', top: 0 });
            header.css({ paddingBottom: 0 });
            return;
        }
        var top = $(window).scrollTop();

        var menuHeight = header.height();
        if (top > menuHeight + margin) {
            var height = menuBg.height();
            header.css({ paddingBottom: height });

            if (!menuBg.hasClass('navigation--scroll')) {
                menuBg
                    .addClass('navigation--scroll')
                    .css({ top: -height })
                    .stop(true)
                    .animate({ top: 0 });
            }
            menuBg.addClass('navigation--scroll').css({ position: 'fixed' });
        } else {
            menuBg.removeClass('navigation--scroll').stop(true, true).css({ position: '', top: 0 });
            header.css({ paddingBottom: 0 });
        }
    }

    update();
    $(window).scroll(update).resize(update);
});

// Проще было бы использовать $(window).on("load") но он ждет загрузки всех картинок на странице
$(document).ready(function () {
    $.each($('.to-align'), function (i, item_main) {
        var images = $(item_main).find('img');
        images.on('load', checkImagesLoaded);
        checkImagesLoaded();

        function checkImagesLoaded() {
            var completeImages = images.filter(function () {
                return this.complete;
            });
            if (completeImages.length !== images.length) {
                return;
            }
            $(item_main).find('.to-align-block').css('height', '');
            if ($(window).width() < 480) {
                return;
            }
            var height = item_main.offsetHeight;
            height = height - 10;
            $(item_main).find('.to-align-block').css('height', height);
        }

        $(window).resize(checkImagesLoaded);
    });
});

$(document).ready(function () {
    var countryInfo = {
        russia: {
            name: 'Россия',
            participant: 1301013,
        },
        ukraine: {
            name: 'Украина',
            participant: 11388,
        },
        kazakhstan: {
            name: 'Казахстан',
            participant: 8937,
        },
        kyrgyzstan: {
            name: 'Киргизия',
            participant: 2891,
        },
        belarus: {
            name: 'Беларусь',
            participant: 2491,
        },
        moldova: {
            name: 'Молдова',
            participant: 1180,
        },
        kndr: {
            name: 'КНДР',
            participant: 100,
        },
        turkmenistan: {
            name: 'Туркменистан',
            participant: 81,
        },
        tajikistan: {
            name: 'Таджикистан',
            participant: 35,
        },
        denmark: {
            name: 'Дания',
            participant: 32,
        },
        mongolia: {
            name: 'Монголия',
            participant: 23,
        },
        latvia: {
            name: 'Латвия',
            participant: 22,
        },
        uzbekistan: {
            name: 'Узбекистан',
            participant: 15,
        },
        czech: {
            name: 'Чехия',
            participant: 14,
        },
        greece: {
            name: 'Греция',
            participant: 11,
        },
        hungary: {
            name: 'Венгрия',
            participant: 9,
        },
        mozambique: {
            name: 'Мозамбик',
            participant: 9,
        },
        israel: {
            name: 'Израиль',
            participant: 4,
        },
        turkie: {
            name: 'Турция',
            participant: 2,
        },
    };
    var colorsType = {
        one: {
            count: 1300000,
            color: '#3A7103',
        },
        two: {
            count: 12000,
            color: '#87BF2D',
        },
        three: {
            count: 100,
            color: '#BED94F',
        },
        four: {
            count: 20,
            color: '#ddff51',
        },
    };

    var colorsTypeWinter = {
        one: {
            count: 1300000,
            color: '#0193CB',
        },
        two: {
            count: 12000,
            color: '#EC8C69',
        },
        three: {
            count: 100,
            color: '#FFB35B',
        },
        four: {
            count: 20,
            color: '#FEDD81',
        },
    };

    $('.map > g').each(function (index, value) {
        if (value.id) {
            changeColorForMap(countryInfo, colorsType, value);
        }
    });

    $('.map-winter > g').each(function (index, value) {
        if (value.id) {
            changeColorForMap(countryInfo, colorsTypeWinter, value);
        }
    });

    function changeColorForMap(countryInfo, colorsType, point) {
        var numberOfPeople = countryInfo[point.id].participant;
        var path = $(point).find('path');

        $(colorsType).each(function (index, value) {
            if (numberOfPeople >= value.one['count']) {
                path.css('fill', value.one['color']);
            } else if (
                numberOfPeople <= value.two['count'] &&
                countryInfo[point.id].participant > value.three['count']
            ) {
                path.css('fill', value.two['color']);
            } else if (
                numberOfPeople <= value.three['count'] &&
                countryInfo[point.id].participant >= value.four['count']
            ) {
                path.css('fill', value.three['color']);
            } else {
                path.css('fill', value.four['color']);
            }
        });
    }

    var description = $(
        '.olymp-s24-map__description, .olymp-s26-map__description, .olymp-s27-map__description',
    );
    $('.country').hover(
        function () {
            description.addClass('active');
            description.html(
                countryInfo[$(this).attr('id')].name +
                    '<br> Участий: ' +
                    countryInfo[$(this).attr('id')].participant,
            );
        },
        function () {
            description.removeClass('active');
        },
    );

    $('.olymp-s24-map__block, .olymp-s26-map__block, .olymp-s27-map__block').on(
        'mousemove',
        function (e) {
            var offsetTop =
                $('.st-header__bg').outerHeight() +
                $('.i-menu').outerHeight() +
                $('.dg-sys-msg__wrapper').outerHeight() +
                75;
            description.css({
                left: e.pageX,
                top: e.pageY - offsetTop,
            });
        },
    );

    var animationStarted = false;
    $(window).scroll(function () {
        if (animationStarted) return;
        if ($('#mountain').length) {
            if (
                $(window).scrollTop() + $(window).height() >
                $('#mountain').height() + $('#mountain').offset().top
            ) {
                $('#lineAnimate')[0].beginElement();
                animationStarted = true;
            }
        }
        if ($('.s25-information').length) {
            if (
                $(window).scrollTop() + $(window).height() >
                $('.s25-information').height() + $('.s25-information').offset().top + 100
            ) {
                $('.s25-information').addClass('active');
            } else {
                $('.s25-information').removeClass('active');
            }
        }
        if ($('.s25-fixed-form').length) {
            if (
                $(window).scrollTop() + $(window).height() >
                $('.s25-information').height() + $('.s25-information').offset().top
            ) {
                $('.s25-fixed-form').addClass('active');
            } else {
                $('.s25-fixed-form').removeClass('active');
            }
        }
        if ($('.s25-car').length) {
            if (
                $(window).scrollTop() + $(window).height() >
                $('.s25-car').height() + $('.s25-car').offset().top
            ) {
                $('.s25-car').addClass('active');
            }
        }
    });

    $('.s25-second').mousemove(function (event) {
        var eye = $('.s25-advantages__earth div');
        var x = eye.offset().left + eye.width() / 2;
        var y = eye.offset().top + eye.height() / 2;
        var rad = Math.atan2(event.pageX - x, event.pageY - y);
        var rot = rad * (180 / Math.PI) * -1 + 180;
        eye.css({
            transform: 'rotate(' + rot + 'deg)',
        });
    });

    $('.switch .button').click(function () {
        $('.switch .button').addClass('inactive');
        $(this).removeClass('inactive');
        if ($(this).hasClass('on')) {
            $('.s25-economy').addClass('active');
        } else {
            $('.s25-economy').removeClass('active');
        }
    });

    var rocketPosTop = 3500;
    var cloudPosTop = 2100;
    if ($(window).width() > 1500) {
        $(window).scroll(function () {
            var movement = -parseInt($(this).scrollTop() / 2);
            var movementTwo = -parseInt($(this).scrollTop() / 5);
            $('.olymp-s24-rocket__img, .olymp-s26-rocket__img, .olymp-s27-rocket__img').css({
                transform: 'translateY(' + (movement + rocketPosTop) + 'px)',
            });
            $('.olymp-s24-rocket__img-cloud').css({
                transform: 'translateY(' + (movementTwo + cloudPosTop) + 'px)',
            });
        });
    }

    $('.basic').on({
        mouseenter: function () {
            $('.olymp-s24-free__basic-plus, .olymp-s26-free__basic-plus').addClass('active');
        },
        mouseleave: function () {
            $('.olymp-s24-free__basic-plus, .olymp-s26-free__basic-plus').removeClass('active');
        },
    });

    $('.hight').on({
        mouseenter: function () {
            $('.olymp-s24-free__hight-plus, .olymp-s26-free__hight-plus').addClass('active');
        },
        mouseleave: function () {
            $('.olymp-s24-free__hight-plus, .olymp-s26-free__hight-plus').removeClass('active');
        },
    });
});

window.setVideo = function setVideo() {
    $('.player.kr-play-icon').on('click', function () {
        if (!$(this).hasClass('kr-play-icon')) return;
        $(this).removeClass('kr-play-icon');
        window[$(this).attr('id')].Play($(this).attr('data-url'));
    });
}

window.i_showBookTooltip = function i_showBookTooltip(e) {
    iu.tooltip(
        e,
        "<div class='tooltip'>Выберите для данного материала учебник из нашей базы учебников. Это значительно повысит качество отображения Вашего материала на сайте.</div>",
        { position: 'top' },
    );
}

$(function () {
    var menu = $('.kr-header__menu');
    var originalPosition = menu.parent();
    var container = menu.closest('.container');
    var minWidth = 992;

    function updateMenuPosition() {
        var width = $(window).width();
        if (width < minWidth) {
            menu.appendTo(container);
        } else {
            menu.appendTo(originalPosition);
            menu.show();
            $('.a-admin.kr-sidebar-menu').show();
            $('#aa-header').find('.i-menu-list').css({ display: '' });
        }
    }

    updateMenuPosition();
    $(window).resize(updateMenuPosition);
});

window.numerize = function numerize(count, forms) {
    var form = 2;
    var mod2 = count % 100;
    if (mod2 < 10 || mod2 > 20) {
        var mod = count % 10;
        if (mod === 1) form = 0;
        else if (mod > 0 && mod < 5) form = 1;
    }
    return forms[form] ? forms[form].replace(/{n}/, count) : count;
}

$(function () {
    $('.select-category').attr('size', parseInt($('.select-category option').length));
});

window.saveScrollPosition = function saveScrollPosition() {
    try {
        sessionStorage.scrollPosition = $(window).scrollTop();
    } catch (e) {}
}

window.restoreScrollPosition = function restoreScrollPosition() {
    try {
        if (sessionStorage.scrollPosition) {
            $(window).scrollTop(sessionStorage.scrollPosition || 0);
            sessionStorage.scrollPosition = 0;
        }
    } catch (e) {}
}

$(function () {
    restoreScrollPosition();
});

$(function () {
    var selectorButton = '.i-header-search';
    var selectorInput = '.i-header-search-input';
    var selectorSearch = '.i-header-search';
    var $search = $(selectorSearch);
    var $button = $(selectorButton);
    var $input = $(selectorInput);
    var $form = $input.parent();
    if ($search.data('init')) return;
    $search.data('init', true);
    $button.click(function (event) {
        if (!$search.hasClass('open')) {
            event.preventDefault();
            $search.addClass('open');
            return;
        }
        if (!$input.val().trim()) {
            event.preventDefault();
            if (!$(event.target).closest($form).length) {
                $search.removeClass('open');
            }
        }
    });
    $(document).click(function (e) {
        if (!$(e.target).closest(selectorSearch).length) {
            $search.removeClass('open');
        }
    });
});

window.visitorCounter = function visitorCounter(data) {
    if (!data) return;
    var serverNow = data[0],
        pointStart = data[1],
        pointEnd = data[2];

    if (pointStart === pointEnd) {
        setVisitCounter(pointStart);
        return;
    }

    function hashInt(x) {
        var a = 0;
        a = x | 0;
        a -= a << 6;
        a ^= a >>> 17;
        a -= a << 9;
        a ^= a << 4;
        a -= a << 3;
        a ^= a << 10;
        a ^= a >>> 15;
        return Math.abs(a);
    }

    function randomSequence(start, max, length) {
        return Array.apply(null, {
            length: length,
        }).map(function (e, i) {
            return hashInt(i + start) % max;
        });
    }

    function monotonicRandomSequence(start, max, length, top) {
        var seq = randomSequence(start, max, length);
        if (!seq.length) return [];
        var sum = seq.reduce(function (a, b) {
            return a + b;
        });
        return seq.map(
            (function () {
                var acc = 0;
                return function (v) {
                    return Math.floor((acc += (v / sum) * top));
                };
            })(),
        );
    }

    function setVisitCounter(n) {
        var arr = [];
        while (n > 0) {
            arr.push(n % 10);
            n = Math.floor(n / 10);
        }
        if (!arr) arr = [0];
        var $number = $('.v-counter-numb');
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i] === undefined) break;
            var s = $number.children().eq(-i - 1);
            if (!s || !s.length) {
                s = $('<span>').text(arr[i]);
                if (i % 3 === 0 && i > 0) {
                    s.addClass('v-counter-numb-dot');
                }
                $number.prepend(s);
            } else if (Number(s.text()) !== arr[i]) {
                s.text(arr[i]);
            }
        }
    }

    if (!iu.ivcInterval) iu.ivcInterval = 0;

    function startFakeCounter(start, stop, seed, duration, now) {
        var seq = monotonicRandomSequence(seed, 10, duration, stop - start);
        var i = now || 0;
        clearInterval(iu.ivcInterval);

        function iteration() {
            setVisitCounter(start + seq[i++]);
            if (i >= seq.length) {
                stopInterval();
            }
        }

        iu.ivcInterval = setInterval(iteration, 1000);
        iteration();

        function stopInterval() {
            clearInterval(iu.ivcInterval);
        }

        return stopInterval;
    }

    startFakeCounter(
        pointStart.cnt,
        pointEnd.cnt,
        pointStart.ts,
        pointEnd.ts - pointStart.ts,
        serverNow - pointStart.ts,
    );
}

window.toggleNext = function toggleNext(el) {
    $(el).toggleClass('open').next().stop(true, true).slideToggle();
    return false;
}

window.copyContentToClipBoard = function copyContentToClipBoard(vText, withPopup = true) {
    var temp = $('<input>').val(vText.trim()).appendTo(document.body).select();
    var done = document.execCommand('Copy');
    temp.remove();
    if (!withPopup) return;
    if (done) {
        iu.popup('Скопировано: <br/>' + vText);
    } else {
        iu.popup('Не удалось скопировать');
    }
}

window.modalImg = function modalImg(el, src, width) {
    var img = $(el).find('img').addBack('img');
    if (!img) return;
    iu.dlg.show(
        $('<div>')
            .css({ position: 'relative', padding: '16px' })
            .append(
                '<a style="position:absolute;top:5px;right:5px;" class="kr-modal-close" onclick="iu.dlg.hide();" title="Закрыть"> <i class="icon icon-close"></i> </a>',
                '<img src=' + (src || img.attr('src')) + '>',
            ),
        width ? width : undefined,
    );
}

// Функция для записи кликов на карту
window.SetCourseInfoStat = function SetCourseInfoStat(type, is_return) {
    iu.ajax(null, '/ajax/setCourseInfoStat', {
        click: 1,
        type: type,
        is_return: Boolean(is_return),
    });
}

// === Вынос дублей функцый в одно место ===
window.getTimeRemaining = function getTimeRemaining(time) {
    if (time < 0) time = 0;

    var seconds = Math.floor(time % 60);
    var minutes = Math.floor((time / 60) % 60);
    var hours = Math.floor((time / (60 * 60)) % 24);
    var days = Math.floor(time / (60 * 60 * 24));

    return {
        total: time,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
}
// ==========================================

// === Добавление плагина CashBackPro ===
window.addCashBackProPlugin = function addCashBackProPlugin(id, tpl) {
    var openWindow;

    var vTop = window.screenTop + 10;
    var vLeft = window.screenLeft;

    chrome.runtime.sendMessage('iohpbnkbolbaomhmanomekhmeabejibp', 'version', function (data) {
        if (data !== undefined && data.type !== undefined && data.type === 'success') {
            iu.ajax(null, '/site/documentDownload', { id: id, x: true });
            return true;
        }

        openWindow = window.open(
            'https://chrome.google.com/webstore/detail/cashback-pro/dhlfambenpcadipgldcfbnbgfjepmcel',
            'CashBackPro',
            'top=' + vTop + ',left=' + vLeft + ',width=600,height=240',
        );
        $('div.v-library-commment').append(tpl);

        document.cookie = 'extension_referrer=' + window.location.href + '; ' + document.cookie;

        var timer = setInterval(function () {
            if (openWindow.closed) {
                clearInterval(timer);
                $('#cash-back-pro-plugin-link').remove();
                iu.ajax(null, '/site/documentDownload', { id: id, x: true });
            }
        }, 500);
    });
}

// ======================================
// === Добавление плагина infourok ===
window.addInfourokPlugin = function addInfourokPlugin(id, tpl) {
    var openWindow;

    var vTop = window.screenTop + 40;
    var vLeft = window.screenLeft;

    openWindow = window.open(
        'https://chrome.google.com/webstore/detail/%D0%B8%D0%BD%D1%84%D0%BE%D1%83%D1%80%D0%BE%D0%BA/nodbelcjcbpmaoainddnnabbljbffmec',
        'Infourok',
        'top=' + vTop + ',left=' + vLeft + ',width=600,height=240',
    );
    $('div.v-library-commment').append(tpl);

    document.cookie = 'extension_referrer=' + window.location.href + '; ' + document.cookie;

    var timer = setInterval(function () {
        if (openWindow.closed) {
            clearInterval(timer);
            $('#cash-back-pro-plugin-link').remove();
            iu.ajax(null, '/site/documentDownload', { id: id, x: true });
        }
    }, 500);
}

window.closeAdsOurBanner = function closeAdsOurBanner(bannerId, bannerPosition) {
    if (bannerId && bannerPosition) {
        var bannerPositionId = bannerPosition + '-' + bannerId;
        var banner = document.querySelectorAll('[data-block-position=' + bannerPositionId + ']');
        banner[0].style.display = 'none';
        iu.ajax(null, '/ajax/hideBanner', {
            bannerId: bannerId,
            bannerPosition: bannerPosition,
            isOurBanner: true
        });
    }
}

window.goToTheLesson = function goToTheLesson(id) {
    iu.ajax(null, '/classroom/GetClassroomLink', { id: id });
    iu.ajax(null, '/onlineLessonButtonLog/buttonWasClicked?type=3');
}

window.setCookieLessonWasClosed = function setCookieLessonWasClosed(id) {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 60 * 60;
    now.setTime(expireTime);

    document.cookie = 'lesson-' + id + '=' + id + '; expires=' + now.toGMTString() + ';path=/';
}

window.lessonPopupWasClosed = function lessonPopupWasClosed(id) {
    return document.cookie.indexOf('lesson-' + id + '=') !== -1;
}

window.closeLessonStartedPopup = function closeLessonStartedPopup(id) {
    // rm popup
    var popup = document.getElementById('lesson-started-' + id);
    if (popup) popup.remove();

    // remember it was closed
    setCookieLessonWasClosed(id);
}

window.returnLessonStartedTemplate = function returnLessonStartedTemplate(id, title, teacher, addClose) {
    var close = '';

    if (addClose) {
        close =
            '<a class="possibility__close" onclick="closeLessonStartedPopup(' +
            id +
            ')"><img src="https://fs22.infourok.ru/file/00ac-00008963-7491bfbc.svg" alt="Закрыть"></a>';
    }

    return (
        '<div id="lesson-started-' +
        id +
        '" class="possibility__item possibility__item--active"><div class="possibility__top">' +
        close +
        '                        <p class="possibility__title">Сейчас идет занятие</p>' +
        '                        <p class="possibility__online">' +
        '                            <img src="https://fs22.infourok.ru/file/136b-0000889a-00ef62f3.svg" alt="">' +
        '                            <span>Онлайн</span>' +
        '                        </p>' +
        '                    </div>' +
        '                    <div class="possibility__block">' +
        '                        <div class="possibility__icon">' +
        '                            <img src="https://fs22.infourok.ru/file/136f-0000889e-7a2f4103.svg"' +
        '                                 alt="">' +
        '                        </div>' +
        '                        <div class="possibility__info">' +
        '                            <p class="possibility__subject">' +
        title +
        '</p>' +
        '                            <div class="possibility__teacher">' +
        '                                <div class="possibility__avatar">' +
        '                                    <img src="https://fs22.infourok.ru/file/136c-0000889b-ce423697.svg"' +
        '                                         alt="Учитель">' +
        '                                </div>' +
        '                                <div class="possibility__teacher-info">' +
        '                                    <p> <span>' +
        teacher +
        '</span></p>' +
        '                                </div>' +
        '                            </div>' +
        '                            <button class="possibility__connect"' +
        '                                    onclick="goToTheLesson(' +
        id +
        ')">' +
        '                                Присоединиться' +
        '                            </button>' +
        '                        </div>' +
        '                    </div></div>'
    );
}

window.showLessonPopup = function showLessonPopup(id, lessonTitle, teacherFullName) {
    var template = returnLessonStartedTemplate(id, lessonTitle, teacherFullName, true);
    var popupCover = document.getElementById('d-ask-msg-popup');
    if (popupCover) {
        popupCover.insertAdjacentHTML('beforeend', template);
    }
}

window.showStartLessonPopup = function showStartLessonPopup(id, lessonTitle, teacherFullName) {
    // set popup only if we are not on classroom page
    if (typeof handleLessonStarted === 'function') return;

    // check on popup was closed
    var popupWasClosed = lessonPopupWasClosed(id);
    if (popupWasClosed) return;

    showLessonPopup(id, lessonTitle, teacherFullName);
}

window.showHidePassword = function showHidePassword(item) {
    if (item.classList.contains('active')) {
        item.parentElement.querySelector('input').setAttribute('type', 'password');
        item.classList.remove('active');
    } else {
        item.parentElement.querySelector('input').setAttribute('type', 'text');
        item.classList.add('active');
    }
}

window.getLocaleTimeFromUtc = function getLocaleTimeFromUtc(utcTimeString) {
    if (!utcTimeString) return;
    // we receive time in utc, but can't directly convert it
    // to locale time, because of firefox/chrome/edge different date parse standarts

    // so we create date from utc, which will be with wrong on locale offset
    var dateToStandard = utcTimeString.replace(/ /g, 'T');
    var utcDateInLocale = new Date(dateToStandard);
    var offset = utcDateInLocale.getTimezoneOffset() * -1;

    // and correct it to right date
    return new Date(utcDateInLocale.getTime() + offset * 60000);
}

window.hideSubmenu = function hideSubmenu() {
    localStorage.setItem('dropdownCourseHide', '1');
    var dropdownToggleShow = document.querySelectorAll('.dropdown-toggle--show');
    if (localStorage.getItem('dropdownCourseHide') === '1') {
        if (dropdownToggleShow.length > 0) {
            dropdownToggleShow.forEach(function (item) {
                item.classList.remove('dropdown-toggle--show');
            });
        }
    } else {
        if (dropdownToggleShow.length > 0) {
            dropdownToggleShow.forEach(function (item) {
                item.addEventListener('click', function () {
                    item.classList.remove('dropdown-toggle--show');
                    localStorage.setItem('dropdownCourseHide', '1');
                });
            });
        }
    }
}

window.toggleClass = function toggleClass(elemId, className) {
    var elem = document.getElementById(elemId);
    if (!elem.classList) return;

    if (elem.classList.contains(className)) {
        elem.classList.remove(className);
    } else {
        elem.classList.add(className);
    }
}
