const YMAPS_API_URL = 'https://api-maps.yandex.ru/2.1/?apikey=27b07bfe-ae14-4137-9776-c3cce45ce22a&lang=ru_RU';

let loadYMapsPromise;

window.loadYMaps = (apiUrl = YMAPS_API_URL) => {
  if (!loadYMapsPromise) {
    loadYMapsPromise = new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      scriptElement.src = apiUrl;
      document.body.appendChild(scriptElement);
    })
      .then(() => new Promise((resolve) => ymaps.ready(resolve)));
  }

  return loadYMapsPromise;
}
